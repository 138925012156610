<template>
    <div class="portfolio-builder">
        <h4 class="dashboard-pages__title">Here's where you collate all your CPD into a polished professional portfolio</h4>
        <b-card>
            <div v-if="stage !== stages.DOWNLOAD_PAGE">


        <b-progress class="progress" :max="stages.DOWNLOAD_PAGE">
            <b-progress-bar class="progress-bar" :value="stage">
                Step {{stage}} / {{stages.DOWNLOAD_PAGE}}
            </b-progress-bar>
        </b-progress>
        </div>

        <b-row v-if="stage === 1">

            <b-col sm="12" class="mt-4">
                <h5 class="dashboard-pages__title mb-0">What to Include</h5>
                <p>Select which pages you want to include in your portfolio.</p>

                <b-form-checkbox-group class="ovr-form__checkbox" switches id="pages-to-include" stacked name="pages" v-model="portfolioConfiguration.pagesToInclude" :options="includedPageOptions"></b-form-checkbox-group>


            </b-col>

        </b-row>
    
        <b-row v-if="stage === 2">

            <b-col sm="12" class="mt-4">
                <h5 class="dashboard-pages__title mb-0">Front Page</h5>
                <p>Customise the front page of your portfolio.</p>

                <b-row>
                    <b-col md="12" xl="6">
                        <CustomInput placeholder="Type the first line here" type="text" @updateVal="(val) => portfolioConfiguration.title.line1 = val" :incomingValue="portfolioConfiguration.title.line1" label="Title Line 1" :wordCount="$charCount(portfolioConfiguration.title.line1)" maxChars="24" />
                        <CustomInput placeholder="Type the second line here" type="text" @updateVal="(val) => portfolioConfiguration.title.line2 = val" :incomingValue="portfolioConfiguration.title.line2" label="Title Line 2" :wordCount="$charCount(portfolioConfiguration.title.line2)" maxChars="24" />

                        <p class="mb-0"><information-outline-icon></information-outline-icon> Stuck for ideas? Try one of these:</p>
                        <ul class="title-ideas">
                            <li><a href="#" @click.prevent="setTitle('Professional', 'Portfolio')">Professional Portfolio</a></li>
                            <li><a href="#" @click.prevent="setTitle('CPD Portfolio', '')">CPD Portfolio</a></li>
                        </ul>
                    </b-col>
                    <b-col md="12" xl="6">
                        <b-card body-class="front-page__preview-card">
                            <PortfolioFrontPagePreview :title="portfolioConfiguration.title" :selectedPortfolioBackground="selectedPortfolioBackground" class="page-preview"/>
                            <div >
                            <b-button-toolbar class="front-cover-selector" key-nav justify aria-label="Toolbar with button groups">
                                <b-button-group class="mx-1">
                                <b-button variant="primary" :disabled="!selectedPortfolioBackground" @click="decrementBackgroundSelection">&lsaquo;</b-button>
                                </b-button-group>
                                <b-button-group class="mx-1">
                                <b-button variant="primary" :disabled="selectedPortfolioBackground >= 10" @click="incrementBackgroundSelection">&rsaquo;</b-button>
                                </b-button-group>
                            </b-button-toolbar>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>

            </b-col>

        </b-row>
    
        <b-row v-if="stage === 3">

            <b-col sm="12" class="mt-4">
                <h5 class="dashboard-pages__title mb-0">Date Range</h5>
                <p>Select the range you'd like to pull entries from.</p>

                <b-row>
                    <b-col md="6">
                        <b-form-group>
                            <template #label>
                                <div class="custom-input__group-label">
                                    <p class="label-title mb-0">From</p>
                                </div>
                            </template>
                            <Datepicker v-model="dateRange.from" :bootstrap-styling="true" :format="dateFormat" />
                        </b-form-group>

                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <template #label>
                                <div class="custom-input__group-label">
                                    <p class="label-title mb-0">To</p>
                                </div>
                            </template>
                            <Datepicker v-model="dateRange.to" :bootstrap-styling="true" :format="dateFormat" />
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-col>
    
            <b-col sm="12" class="mt-2" v-if="tags.length > 0">
                <h3>Tags</h3>
                <p>Select the tags you want to include entries from.</p>
                <b-form-checkbox-group stacked v-model="selectedTags" :options="tagOptions" />
            </b-col>

        </b-row>
    
        <b-row v-if="stage === stages.DIARY_ENTRIES">
            <b-col sm="12" class="mt-4 data-table__parent">
                <h5 class="dashboard-pages__title mb-3">Select Diary Entries</h5>
                <Pulse v-if="loading" color="#333333"/>
                <b-table class="data-table" thead-class="table-header" tbody-tr-class="table-body__tr"
                            tbody-class="table-body" striped :items="entries.diary" :fields="entriesFields">
                    <template v-slot:head(title)="data">
                        {{ data.label }}
                        <a href="#" class="select-link" @click.prevent="selectAllDiary">Select All</a>
                        <a href="#" class="select-link" @click.prevent="deselectAllDiary">Deselect All</a>
                    </template>
                    <template v-slot:head(select-evidence)="data">
                        {{ data.label }}
                        <a href="#" class="select-link" @click.prevent="selectAllEvidence">Select All</a>
                        <a href="#" class="select-link" @click.prevent="deselectAllEvidence">Deselect All</a>
                    </template>
                    <template v-slot:cell(title)="data">
                        {{data.item.title}}<br />
                        <b-badge pill variant="secondary" v-for="tag in data.item.tags" v-bind:key="tag">{{tag}}</b-badge>
                    </template>
                    <template v-slot:cell(select)="data">
                        <b-form-checkbox v-model="data.item.selected" switch class="ovr-form__checkbox"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(select-evidence)="data">
                        <b-form-checkbox v-for="ev in data.item.evidence" class="ovr-form__checkbox" variant="info" v-bind:key="ev.id" :disabled="!data.item.selected" v-model="ev.selected" switch>{{ev.name.replaceAll('\+', ' ')}}</b-form-checkbox>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    
        <b-row v-if="stage === stages.REFLECTIVE_ENTRIES">
            <b-col sm="12" class="mt-4, data-table__parent" >
                <h5 class="dashboard-pages__title mb-3">Select Reflective Entries</h5>
                <Pulse v-if="loading" color="#333333"/>
                <b-table class="data-table" thead-class="table-header" tbody-tr-class="table-body__tr"
                            tbody-class="table-body" striped :items="entries.reflections" :fields="entriesFields">
                    <template v-slot:head(title)="data">
                        {{ data.label }}
                        <a href="#" class="select-link" @click.prevent="selectAllReflection">Select All</a>
                        <a href="#" class="select-link" @click.prevent="deselectAllReflection">Deselect All</a>
                    </template>
                    <template v-slot:cell(select)="data">
                        <b-form-checkbox v-model="data.item.selected" switch class="ovr-form__checkbox"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(select-evidence)="data">
                        <b-form-checkbox v-for="ev in data.item.evidence" class="ovr-form__checkbox" variant="info" v-bind:key="ev.id" :disabled="!data.item.selected" v-model="ev.selected" switch>{{ev.name.replaceAll('\+', ' ')}}</b-form-checkbox>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    
        <b-row v-if="stage === stages.DOWNLOAD_PAGE">
            <b-col sm="12">
                <b-alert show variant="dark">
                    <b-row>
                        <b-col sm="2">
                            <img style="width: 100%; border-radius: 50%" :src="profileImage"/>
                        </b-col>
                        <b-col sm="10">
                            <h4 class="alert-heading">Well done!</h4>
                            <p>Good, that’s over {{estimatedPages}} pages of pure CPD. Looks like all your learning has paid off.</p>
                            <p class="mb-0">Click the Download button below to get your printable PDF.</p>
                        </b-col>
                    </b-row>

                </b-alert>
            </b-col>

            <b-row>
                <b-col sm="12" style="padding-left: 30px;">
                    <b-form-checkbox v-model="includeBranding" switch>Include CPDme Branding</b-form-checkbox>
                </b-col>
            </b-row>

        </b-row>
    
            <b-row class="mt-4">
    
            <b-col sm="12" v-if="stage === stages.DOWNLOAD_PAGE && downloading">
                <b-progress variant="info" :max="100" class="mb-3">
                    <b-progress-bar :value="downloadProgresser.progress * 100" >
                        Downloading...
                    </b-progress-bar>
                </b-progress>
            </b-col>
            <b-col sm="12" v-if="stage === stages.DOWNLOAD_PAGE" class="mb-4">
                <small>By creating this portfolio, you confirm that the information provided above is a true reflection of your personal development and does not contain any person, patient, client or service user identifiable data that could breach data protection laws or legislation relating to your employer, governing bodies, professional organisations, code of conduct and associated revalidation guidance. </small>
            </b-col>
            <b-col cols="6">
                <b-button :disabled="downloading" @click="back" v-if="stage !== stages.SELECT_PAGES" size="sm" variant="outline-dark" block>Back</b-button>
            </b-col>
            <b-col cols="6">
                <div v-if="stage !== stages.DOWNLOAD_PAGE">
                    <b-button :disabled="downloading" @click="next" size="sm" variant="outline-dark" block>Next</b-button>
                </div>
                <div v-else>
                    <b-row>
                        <b-col sm="6">
                            <b-button class="ovr-button justify-content-center" :disabled="downloading" @click="download" size="sm" variant="info" block>
                                <span class="loading"  v-if="downloading"><moon-loader class="loader" size="20px" color="#3498DB"></moon-loader></span>
                                <span class="ready" v-else><download-icon /> Download</span>
                            </b-button>
                        </b-col>
                        <b-col sm="6" v-requires-role="'User-Basic'">
                            <b-button class="ovr-button justify-content-center" :disabled="downloading" @click="emailPortfolio" size="sm" variant="info" block>
                                <span class="ready"><email-icon /> Email</span>
                            </b-button>
                        </b-col>
                    </b-row>

                </div>


            </b-col>
        </b-row>

        </b-card>
       

        <b-modal id="videoPreviewModal" hide-footer ref="videoPreviewModal" title="Watch this video while your file gets downloaded"
				size="lg">
            <div class="w-100 d-flex align-items-center justify-content-center flex-column">
                <Pulse v-if="isVideoLoading" color="#333333"/>
                <iframe class="iframe-video" @load="() => isVideoLoading = false" src="https://www.youtube.com/embed/aq54DnKXODY" title="Watch this video while your file gets downloaded" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
		</b-modal>

    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import Pulse from "../../components/loaders/Pulse";
    import PortfolioFrontPagePreview from "../../components/portfolio/PortfolioFrontPagePreview";
    import FakeProgress from 'fake-progress';
    import DownloadIcon from "vue-material-design-icons/Download";
    import EmailIcon from "vue-material-design-icons/Email";
    import Providers from "@/providers";
    import CustomInput from '../../components/common/CustomInput';
    import Managers from '../../managers';

    export default {
        name: "PortfolioBuilder",
        components: {EmailIcon, DownloadIcon, PortfolioFrontPagePreview, Pulse, Datepicker, CustomInput},
        data: function() {
            return {
                isVideoLoading: true,
                downloadProgresser: new FakeProgress({
                    timeConstant : 15000,
                    autoStart : false
                }),
                checked: true,
                stages: {
                    SELECT_PAGES: 1,
                    DIARY_ENTRIES: 4,
                    REFLECTIVE_ENTRIES: 5,
                    DOWNLOAD_PAGE: 6
                },
                governingBodies: null,
                clinicalSkillsLinked: false,
                stage: 1,
                loading: true,
                downloading: false,
                dateFormat: 'dd/MM/yyyy',
                includeBranding: true,
                entriesFields: [
                    {
                        label: '',
                        thStyle: {'width': '60px'},
                        key: 'select'
                    },
                    {
                        label: 'Title',
                        key: 'title'
                    },
                    {
                        label: 'Date',
                        key: 'date',
                        thStyle: {'width': '150px'},
                        formatter: 'entryDate'
                    },
                    {
                        label: 'Evidence',
                        key: 'select-evidence'
                    }
                ],
                entries: {
                  diary: [],
                  reflections: []
                },
                dateRange: {
                    from: this.moment().subtract(1, 'year').toDate(),
                    to: new Date()
                },
                selectedTags: [],
                portfolioConfiguration: {
                    title: {
                        line1: '',
                        line2: ''
                    },
                    pagesToInclude: []
                },
                tags: [],
                selectedPortfolioBackground: null,
            }
        },
        watch: {
            tagOptions() {
                this.selectedTags = this.tagOptions.map(option => option.value);
            }
        },
        methods: {
            setTitle(line1, line2) {
                this.portfolioConfiguration.title.line1 = line1;
                this.portfolioConfiguration.title.line2 = line2;
            },
            next() {
                this.stage++;
                this.pageChanged();

            },
            back() {
                this.stage--;
                this.pageChanged();
            },
            decrementBackgroundSelection() {
                if (this.selectedPortfolioBackground === 1) {
                    this.selectedPortfolioBackground = null;
                } else {
                    this.selectedPortfolioBackground -= 1;
                }
            },
            incrementBackgroundSelection() {
                if (!this.selectedPortfolioBackground) {
                    this.selectedPortfolioBackground = 1;
                } else {
                    this.selectedPortfolioBackground += 1;
                }
            },
            pageChanged() {
                if (this.stage === this.stages.DIARY_ENTRIES) {
                    this.loading = true;
                    this.getEntries();
                }
                window.scrollTo(0, 0);
            },
            emailPortfolio() {
                this.$http.post('portfolio/v2/email', this.portfolioRequest).then(() => {
                   this.$success('Portfolio Requested', 'You should receive an email within 10 minutes with a link to download your portfolio.')
                }).catch((resp) => {
                    this.$httpError('Failed to Request Portfolio', resp);
                });
            },
            downloadFile(link) {
                this.$http.get(link, {
                    responseType: 'blob'
                }).then((response) => {
                    let result = document.createElement('a');
                    let contentDisposition = response.headers.get('Content-Disposition') || '';
                    let filename;
                    if (contentDisposition) {
                        filename = contentDisposition.split('filename=')[1];
                        filename = filename.replace(/"/g, "")
                    } else {
                        filename = "portfolio.pdf";
                    }

                    return response.blob()
                        .then(function(data) {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(data, filename);
                                return null;
                            } else {
                                result.href = window.URL.createObjectURL(data);
                                result.target = '_self';
                                result.download = filename;
                                return result;
                            }
                        });
                }).then((link) => {
                    if (link) {
                        link.click();
                    }
                }).catch((resp) => {
                    this.$httpError('Failed to download portfolio', resp);
                }).finally(() => {
                    this.downloadProgresser.end();
                    this.downloading = false;
                })
            },
            pollDownloadCompleted(uuid) {
                this.$http.get(`portfolio/v2/download/${uuid}`).then(({data}) => {
                    if (data.state === 'Completed' || data.state === 'Failed') {
                        if (data.state === 'Completed') {
                            this.downloadFile(data.downloadLink);
                        } else {
                            this.$error('Failed to download portfolio', 'Please try again or contact support if the issue continues.');
                            this.downloadProgresser.end();
                            this.downloading = false;
                        }
                    } else {
                        setTimeout(() => {
                            this.pollDownloadCompleted(uuid);
                        }, 3000);
                    }
                }).catch((resp) => {
                    this.$httpError('Failed to download portfolio', resp);
                    this.downloadProgresser.end();
                    this.downloading = false;
                })
            },
            download() {
                this.downloading = true;
                this.downloadProgresser.start();
                this.$refs.videoPreviewModal.show()
                this.$http.post('portfolio/v2/download', this.portfolioRequest).then((response) => {
                    this.pollDownloadCompleted(response.data.uuid);
                }).catch((resp) => {
                      this.$httpError('Failed to download portfolio', resp);
                      this.downloadProgresser.end();
                      this.downloading = false;
                })
            },
            getEntries() {
                this.$http.get('entries/limit/1000/page/1').then((resp) => {
                   let entries = resp.body.items;
                   this.entries.diary = entries.filter(e => e.type === 'diary').filter(e => this.entryInRange(e)).map(e => {
                       e.selected = true;
                       if (e.evidence && e.evidence.length > 0) {
                           e.evidence = e.evidence.map(ev => {
                               ev.selected = true;
                               return ev;
                           });
                       }
                       return e;
                   });
                   this.entries.reflections = entries.filter(e => e.type === 'reflection').filter(e => this.entryInRange(e)).map(e => {
                       e.selected = true;
                       if (e.evidence && e.evidence.length > 0) {
                           e.evidence = e.evidence.map(ev => {
                               ev.selected = true;
                               return ev;
                           });
                       }
                       return e;
                   });

                }).then(() => {
                    this.loading = false;
                });
            },
            getGoverningBodies() {
                this.$http.get('profile/governing-bodies').then((resp) => {
                    this.governingBodies = resp.body;
                    this.updateDefaultPagesToInclude();
                }).catch((err) => {
                    this.$httpError('Failed to get governing bodies', err);
                }).finally(() => {
                    this.loading = false;
                });
            },
            getClinicalSkillsLinkStatus() {
                this.$http.get('clinical-skills/auth/link').then(() => {
                    this.clinicalSkillsLinked = true;
                    this.updateDefaultPagesToInclude();
                }).catch(() => {
                    this.clinicalSkillsLinked = false
                })
            },
            entryInRange(entry) {
                let inDateRange = false;
                let hasIncludedTag = false;
                if (entry.date) {
                    let entryDate = this.moment(entry.date);
                    if (entryDate >= this.dateRange.from && entryDate <= this.dateRange.to) {
                        inDateRange = true;
                    }
                }
                if (this.selectedTags.length > 0) {
                    if (entry.tags.length === 0 && this.selectedTags.includes('')) {
                        hasIncludedTag = true;
                    } else {
                        const matchingTags = entry.tags.filter(value => this.selectedTags.includes(value));
                        hasIncludedTag = matchingTags.length > 0;
                    }
                } else {
                    hasIncludedTag = true;
                }
                return inDateRange && hasIncludedTag;
            },
            findEvidenceIds() {
                let evidenceIds = [];
                for (let entries of [this.entries.diary, this.entries.reflections]) {
                    let entriesWithEvidence = entries.filter(entry => entry.evidence);
                    for (let entry of entriesWithEvidence) {
                        for (let ev of entry.evidence) {
                            if (ev.selected) {
                                evidenceIds.push(ev.id);
                            }
                        }
                    }
                }
                return evidenceIds;
            },
            entryDate(val) {
                if (val) {
                    return this.moment(val).format('DD/MM/YYYY');
                }
                return '';
            },
            updateDefaultPagesToInclude() {
                this.portfolioConfiguration.pagesToInclude = this.includedPageOptions.map(v => v.value);
            },
            setSelected(items, selected) {
                items.forEach((i) => {
                    i.selected = selected;
                });
            },
            selectAllDiary() {
                this.setSelected(this.entries.diary, true);
            },
            deselectAllDiary() {
                this.setSelected(this.entries.diary, false);
            },
            selectAllReflection() {
                this.setSelected(this.entries.reflections, true);
            },
            deselectAllReflection() {
                this.setSelected(this.entries.reflections, false);
            },
            selectAllEvidence() {
                this.setSelected(this.entries.diary.map((d) => d.evidence).flat(), true);
            },
            deselectAllEvidence() {
                this.setSelected(this.entries.diary.map((d) => d.evidence).flat(), false);
            },
            loadTags() {
                Providers.profile.tags().then((tags) => {
                    this.tags = tags;
                }).catch((err) => {
                    this.$httpError('Failed to load tags', err);
                })
            }
        },
        computed: {
            profileImage() {
                let authToken = Managers.authentication.loginToken();
                return `${process.env.VUE_APP_API_ROOT || ""}profile/image?auth_token=${authToken}`
            },
            portfolioRequest() {
              return {
                  dateFrom: this.dateRange.from.toISOString().slice(0,10),
                  dateTo: this.dateRange.to.toISOString().slice(0,10),
                  include: this.portfolioConfiguration.pagesToInclude,
                  title: {
                      line1: this.portfolioConfiguration.title.line1,
                      line2: this.portfolioConfiguration.title.line2
                  },
                  diaryEntries: this.diaryEntryIds,
                  reflectiveEntries: this.reflectiveEntryIds,
                  evidenceIds: this.findEvidenceIds(),
                  includeBranding: this.includeBranding,
                  selectedPortfolioCover: this.selectedPortfolioBackground
              }
            },
            includedPageOptions() {
                let defaultOptions = [
                    { value: 'FRONT_PAGE', text: 'Front Page'},
                    { value: 'CONTENTS_PAGE', text: 'Contents Page' },
                    { value: 'PROFESSIONAL_INFORMATION', text: 'Professional Information & Qualifications' },
                    { value: 'SUMMARY_OF_WORK', text: 'Summary of Work' },
                    { value: 'JOB_DESCRIPTION', text: 'Job Description' },
                    { value: 'PERSONAL_STATEMENT', text: 'Personal Statement' },
                    { value: 'PROFILE_PICTURE', text: 'Profile Picture on Personal Statement'},
                    { value: 'CPD_INFOGRAPHIC', text: 'Infographic CPD Overview Report'}
                ];

                if (this.governingBodies && this.governingBodies.includes('EFI'))
                {
                  defaultOptions.push({value: 'SUMMARY_OF_WORK_EFI', text: 'Summary of Work - EFI Format'});
                }

                if (this.governingBodies && this.governingBodies.includes('HCPC')) {
                    defaultOptions.push({value: 'HCPC_PROFILE', text: 'Meeting HCPC Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('SC – Science Council')) {
                  defaultOptions.push({value: 'SC_PROFILE', text: 'Meeting SC – Science Council Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('AHCS – Academy for Healthcare Science')) {
                  defaultOptions.push({value: 'AHCS_PROFILE', text: 'Meeting AHCS – Academy for Healthcare Science Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('GOsC - General Osteopathic Council')) {
                  defaultOptions.push({value: 'GOSC_PROFILE', text: 'Meeting GOsC - General Osteopathic Council Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('SWE - Social Work England')) {
                  defaultOptions.push({value: 'SWE_PROFILE', text: 'Meeting SWE - Social Work England Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('PSTT – Professional Standards for Teachers and Trainers')) {
                  defaultOptions.push({value: 'PSTT_PROFILE', text: 'Meeting PSTT – Professional Standards for Teachers and Trainers Standards'});
                }

                if (this.governingBodies && this.governingBodies.includes('CORU')) {
                    defaultOptions.push({value: 'CORU_REVIEW_PLAN', text: 'CORU Review & Plan'});
                }
                if (this.governingBodies && this.governingBodies.includes('Institute of Chartered Accountants in England and Wales (ICAEW)')) {
                    defaultOptions.push({value: 'ICAEW_ROLE_RESPONSIBILITIES', text: 'Institute of Chartered Accountants in England and Wales (ICAEW) Role & Responsibilities'});
                }
                if (this.governingBodies && this.governingBodies.includes('EPRR')) {
                    defaultOptions.push({value: 'EPRR_STANDARDS', text: 'Meeting Emergency Preparedness, Resilience and Response (EPRR) Standards'});
                }
                if (this.governingBodies && this.governingBodies.includes('NMC')) {
                    defaultOptions.push({value: 'NMC_STANDARDS', text: 'Nursing and Midwifery Council Standards'})
                }
                if (this.clinicalSkillsLinked) {
                    defaultOptions.push({value: 'CLINICAL_SKILLS_TEXTUAL', text: '(Appendix) Clinical Skills Report - Textual'});
                    defaultOptions.push({value: 'CLINICAL_SKILLS_GRAPHICAL', text: '(Appendix) Clinical Skills Report - Graphical'});
                }
                return defaultOptions
            },
            diaryEntryIds() {
                return this.entries.diary.filter(e => e.selected).map(e => e.id);
            },
            reflectiveEntryIds() {
                return this.entries.reflections.filter(e => e.selected).map(e => e.id);
            },
            estimatedPages() {
              return this.diaryEntryIds.length + this.reflectiveEntryIds.length + 8;
            },
            tagOptions() {
                return [
                    { text: 'Untagged', value: '' },
                    ...this.tags.map(tag => ({text: tag, value: tag }))
                ];
            }
        },
        created() {
            this.updateDefaultPagesToInclude();
            this.getGoverningBodies();
            this.getClinicalSkillsLinkStatus();
            this.loadTags();
        }
    }
</script>

<style scoped lang="scss">
    .iframe-video {
        width: 100%;
        height: 480px;
    }

    .select-link {
        font-size: 9px;
        margin-right: 5px;
        font-weight: bold;
        display:block;
    }

    .portfolio-builder {
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }

    h2 {
        text-align: center;
    }

    .data-table {
        table-layout:fixed;
        width:100%;
        min-width: 680px;

    }

    div.loader {
        margin-left: auto;
        margin-right: auto;
    }

    .page-preview {
        margin-left: auto;
        margin-right: auto;
    }
    ul.title-ideas a {
        color: #2c3e50;
    }

    .front-page__preview-card {
        background-color: $background;
        padding: 64px;
    }

    .front-cover-selector {
        margin-top: -150px;
        margin-left: -25px;
        margin-right: -25px;
        padding-bottom: 100px;
    }

</style>
